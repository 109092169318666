/* eslint import/prefer-default-export: "off" */
import * as amplitude from "@amplitude/analytics-browser";
import { readJsonScriptValue } from "../utils/json_script";

const amplitudeApiKey = readJsonScriptValue("settings-amplitude-api-key");
const userIdEmailAddress = readJsonScriptValue("user-email-address", {
  defaultValue: null,
});
const version = readJsonScriptValue("settings-source-version");

const amplitudeOptions = {
  autocapture: true,
  appVersion: version,
};

// only initialize Amplitude if the API key is present
if (amplitudeApiKey !== undefined) {
  amplitude.init(amplitudeApiKey, userIdEmailAddress, amplitudeOptions);
}
// if the API key is missing, but a user is logged in, log an error
if (amplitudeApiKey === undefined && userIdEmailAddress) {
  /* eslint-disable-next-line no-console */
  console.error(
    `Amplitude API key is missing, but user ${userIdEmailAddress} is logged in`,
  );
}

const extendEventProperties = (properties) => ({
  ...properties,
  context: "Web Application",
});

export const logEvent = (evt, properties = {}) => {
  // only log events if the API key is present and silently ignore if it is not
  if (amplitudeApiKey !== undefined) {
    const eventProperties = extendEventProperties(properties);
    amplitude.track(evt, eventProperties);
  }
};

logEvent("page.load", { url: window.location.href });
